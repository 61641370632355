<template>
    <div>
        <new-attribute></new-attribute>
    </div>
</template>

<script>
    import NewAttribute from "../components/samuraikittens/attibute";
    export default {
        components: {NewAttribute}
    }
</script>

<style scoped>

</style>