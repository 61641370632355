<template>
    <div>
        <ogClaims></ogClaims>
    </div>
</template>

<script>
    import ogClaims from "../components/ogClaims";
    export default {
        components: {ogClaims}
    }
</script>

<style scoped>

</style>