import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../views/Home.vue";
import viewer from "../views/viewer.vue";
import attribute from "../views/attribute.vue";

import bitkittensView from "../views/bk-view.vue";
import bitkittensAttribute from "../views/bk-attributes.vue";

import samuraikittensMint from "../views/sk-mint.vue";
import samuraikittensView from "../views/sk-view.vue";
import samuraikittensAttribute from "../views/sk-attributes.vue";

import ogClaims from "../views/og-claims.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
    
  {
    path: "/viewer",
    name: "viewer",
    component: viewer,
  },
    
  {
    path: "/attribute",
    name: "attribute",
    component: attribute,
  },
    
  {
    path: "/bk-view",
    name: "bitkittensView",
    component: bitkittensView,
  },
    
  {
    path: "/bk-attributes",
    name: "bitkittensAttribute",
    component: bitkittensAttribute,
  },

  {
    path: "/sk-mint",
    name: "samuraikittensMint",
    component: samuraikittensMint,
  },
    
  {
    path: "/sk-view",
    name: "samuraikittensView",
    component: samuraikittensView,
  },
    
  {
    path: "/sk-attributes",
    name: "samuraikittensAttribute",
    component: samuraikittensAttribute,
  },

  {
    path: "og-claims",
    name: "ogClaims",
    component: ogClaims,
  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
