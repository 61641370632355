<template>
    <div>
        <pump-kittens-viewer></pump-kittens-viewer>
    </div>
</template>

<script>
    import PumpKittensViewer from "../components/PumpKittensViewer";
    export default {
        name: "viewer",
        components: {PumpKittensViewer}
    }
</script>

<style scoped>

</style>