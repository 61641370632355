<template>
    <div>
        <bk-attribute></bk-attribute>
    </div>
</template>

<script>
    import bkAttribute from "../components/bitkittens/attibute";
    export default {
        components: {bkAttribute}
    }
</script>

<style scoped>

</style>