<template>
    <div>
        <new-mint></new-mint>
    </div>
</template>

<script>
    import NewMint from "../components/samuraikittens/mint";
    export default {
        components: {NewMint}
    }
</script>

<style scoped>

</style>