<template>
  <div class="attr_tab">
    <div v-for="(attr, idx) in attributes" :key="'attr' + idx">
      <div class="category">
        <div class="text-h6">{{ attr.category }}</div>
      </div>
      <div v-for="(cat, c_idx) in attr.items" :key="'cat' + c_idx">
        <div class="d-flex align-items-center justify-space-between">
          <div>
            {{ c_idx + 1 }}. <u>{{ cat.name }}</u>
          </div>
          <div>{{ cat.count }} <span>/</span> {{ attr.total }}</div>
        </div>
        <div class="d-flex flex-nowrap imgRow">
<!--          <img
            v-for="(img, i_idx) in cat.images"
            :key="'img' + i_idx"
            :src="img"
            class="img-attrb"
          />-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "new-attribute",
  data() {
    return {
      attributes: [
        {
          category: "Super rarity",
          total: 500,
          items: [
           {
              name: "Helmet",
              count: 37,
              images: [
              ],
            },
            {
              name: "Helmet with Horns",
              count: 3,
              images: [
              ],
            },
            {
              name: "Ninja",
              count: 31,
              images: [
              ],
            },
          ],
        },
        {
          category: "Rarity",
          total: 500,
          items: [
            {
              name: "Amulet",
              count: 17,
              images: [
              ],
            },
            {
              name: "Arm Cuff",
              count: 35,
              images: [
              ],
            },
            {
              name: "Armor",
              count: 77,
              images: [
              ],
            },
            {
              name: "Barrette",
              count: 22,
              images: [
              ],
            },
            {
              name: "Cape",
              count: 5,
              images: [
              ],
            },
           {
              name: "Cloak",
              count: 39,
              images: [
              ],
            },
            {
              name: "Gloves",
              count: 1,
              images: [
              ],
            },
            {
              name: "Robe",
              count: 65,
              images: [
              ],
            },
            {
              name: "Shield",
              count: 7,
              images: [
              ],
            },
            {
              name: "Warrior Mask",
              count: 24,
              images: [
              ],
            },
          ],
        },
        {
          category: "Weapon",
          total: 500,
          items: [
            {
              name: "Ax",
              count: 5,
              images: [
              ],
            },
            {
              name: "Axe",
              count: 23,
              images: [
              ],
            },
           {
              name: "Battle Fan",
              count: 10,
              images: [
              ],
            },
            {
              name: "Battle Staff",
              count: 5,
              images: [
              ],
            },
             {
              name: "Battle Stick",
              count: 27,
              images: [
              ],
            },
            {
              name: "Bow",
              count: 2,
              images: [
              ],
            },
            {
              name: "Cane",
              count: 77,
              images: [
              ],
            },
           {
              name: "Cudgel",
              count: 1,
              images: [
              ],
            },
            {
              name: "Dagger",
              count: 2,
              images: [
              ],
            },
            {
              name: "Deer Horn",
              count: 8,
              images: [
              ],
            },
           {
              name: "Fire Ball",
              count: 3,
              images: [
              ],
            },
           {
              name: "Guan Dao",
              count: 24,
              images: [
              ],
            },
            {
              name: "Halberd",
              count: 24,
              images: [
              ],
            },
            {
              name: "Hammer",
              count: 7,
              images: [
              ],
            },
           {
              name: "Hook",
              count: 5,
              images: [
              ],
            },
            {
              name: "Iron Claws",
              count: 5,
              images: [
              ],
            },
            {
              name: "Knife",
              count: 9,
              images: [
              ],
            },
           {
              name: "Magic",
              count: 1,
              images: [
              ],
            },
           {
              name: "Magic Ball",
              count: 41,
              images: [
              ],
            },
           {
              name: "Morgenstern",
              count: 6,
              images: [
              ],
            },
            {
              name: "Nunchucks",
              count: 11,
              images: [
              ],
            },
            {
              name: "Sai",
              count: 6,
              images: [
              ],
            },
           {
              name: "Shield",
              count: 4,
              images: [
              ],
            },
            {
              name: "Shuriken",
              count: 1,
              images: [
              ],
            },
            {
              name: "Sia",
              count: 3,
              images: [
              ],
            },
           {
              name: "Spear",
              count: 70,
              images: [
              ],
            },
            {
              name: "Sword",
              count: 91,
              images: [
              ],
            },
          ],
        },
      {
          category: "Action",
          total: 500,
          items: [
            {
              name: "Backflipping",
              count: 1,
              images: [
              ],
            },
            {
              name: "Fighting Stance",
              count: 257,
              images: [
              ],
            },
            {
              name: "Jumping",
              count: 152,
              images: [
              ],
            },
            {
              name: "Kicking",
              count: 14,
              images: [
              ],
            },
            {
              name: "Meditating",
              count: 2,
              images: [
              ],
            },
            {
              name: "Punching",
              count: 2,
              images: [
              ],
            },
            {
              name: "Running",
              count: 10,
              images: [
              ],
            },
          ],
        },
        {
          category: "Uniqueness",
          total: 500,
          items: [
            {
              name: "Gloves",
              count: 8,
              images: [
              ],
            },
            {
              name: "Hair",
              count: 107,
              images: [
              ],
            },
            {
              name: "Hat",
              count: 66,
              images: [
              ],
            },
            {
              name: "Headband",
              count: 59,
              images: [
              ],
            },
            {
              name: "Warrior Circlet",
              count: 64,
              images: [
              ],
            },
          ],
        }
      ],
    };
  },
};
</script>

<style scoped>
.img-attrb {
  object-fit: contain !important;
  width: 50px;
  height: 50px;
  margin: 2px;
}

.imgRow {
  padding: 16px;
  padding-top: 4px;
}

.category {
  padding-bottom: 10px;
}

.img-attrb {
  width:70px;
  height:70px;
  opacity: .8;
}
</style>