<template>
    <div>
        <new-view></new-view>
    </div>
</template>

<script>
    import NewView from "../components/samuraikittens/view";
    export default {
        components: {NewView}
    }
</script>

<style scoped>

</style>