<template>
  <div class="holder">
    <div class="text-h6">CLAIM FTMs to your wallet address.</div>
    <div class="mx-5 my-5">
      <v-btn @click="claimFTM" color="grey" outlined elevation="4">CLAIM FTM</v-btn>
      <span class="hilight">({{pendingFTM.toFormat(5)}} FTM)</span>
    </div>
  </div>
</template>
<script>

import BigNumber from 'bignumber.js'
export default {
  name: "ogs-view",
  data() {
    return {
    };
  },
  computed: {
     pendingFTM() {
        if(this.$store.state.account) {
          return BigNumber(this.$store.state.samuraikittens.pendingFTM).shiftedBy(-18);
        }
        return BigNumber(0);
      },
  },
  mounted() {
  },
  methods: {
    async claimFTM() {
      try {
        await this.$store.dispatch('claimFTM')
      }
      catch (e) {
        this.error = e;
      }
    },
  }
};
</script>

<style>
  .hilight {
      color:#ffff00;
      margin-left: 10px;
      margin-top: 5px;
      font-family: sans-serif;
      font-size: 14px;
  }
</style>

