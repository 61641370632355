import { render, staticRenderFns } from "./bk-attributes.vue?vue&type=template&id=70c51f26&scoped=true&"
import script from "./bk-attributes.vue?vue&type=script&lang=js&"
export * from "./bk-attributes.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70c51f26",
  null
  
)

export default component.exports