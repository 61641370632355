<template>
    <div>
        <v-btn color="black" dark @click.stop="dialog = true"  outlined elevation="4" class="action" x-small>Send</v-btn>
        <v-dialog v-model="dialog" max-width="450">
            <v-card>
                <v-toolbar v-if="this.contract=='pumpkittens'" color="primary" dark>Send your Pumpkitten to:</v-toolbar>
                <v-toolbar v-if="this.contract=='bitkittens'" color="primary" dark>Send your Bitkitten to:</v-toolbar>
                <v-toolbar v-if="this.contract=='samuraikittens'" color="primary" dark>Send your Samuraikitten to:</v-toolbar>
                <div class="text-h10 my-3 mx-3">{{this.name}}</div>
                <v-col sm="15">
                   <v-text-field v-model="recipientAddress" placeholder="To : Enter recipients address">
                    </v-text-field>
                </v-col>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="transferToken" color="green darken-1" text>
                    Send
                    </v-btn>
                   <v-btn color="green darken-1" text @click="dialog = false">
                    Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
  export default {
    data () {
      return {
        dialog: false,
        recipientAddress: null,
        transfered: false,
        error: false
      }
    },
    props: {
        id: {type: String},
        name: {type: String},
        account: null,
        contract: null
    },
    mounted() {
    },
    unmounted() {
    },
    watch:{
        dialog:function(newValue){
            if(!newValue){
                this.research();
                this.transfered = false;
            }
        }
    },
    methods: {
        transferToken() {         
            if (this.$store.dispatch('transferToken', {
                tokenID:this.id,
                from:this.account,
                to:this.recipientAddress,
                contract:this.contract
            }))
            this.transfered = true;
        },
        async research() {
            if (this.transfered)
            {
                try {
                    await this.$store.dispatch('getTokenIdsOfOwner', {
                        account:this.account,
                        contract:this.contract
                    })

                    await this.$store.dispatch('constructTokenInfo', {
                        account:this.account,
                        contract:this.contract
                    })
                }
                catch (e) {
                    this.error = e;
                }
            }
        }
    }
  }
</script>