<template>
  <div>
    <mint></mint>
  </div>
</template>


<script>
  import Mint from "../components/samuraikittens/mint";
  export default {
    components: {Mint}
  }
</script>