<template>
    <div>
        <attribute></attribute>
    </div>
</template>

<script>
    import Attribute from "../components/attribute";
    export default {
        components: {Attribute}
    }
</script>

<style scoped>

</style>