<template>
  <div class="attr_tab">
    <div v-for="(attr, idx) in attributes" :key="'attr' + idx">
      <div class="category">
        <div class="text-h6">{{ attr.category }}</div>
      </div>
      <div v-for="(cat, c_idx) in attr.items" :key="'cat' + c_idx">
        <div class="d-flex align-items-center justify-space-between">
          <div>
            {{ c_idx + 1 }}.<u>{{ cat.name }}</u>
          </div>
          <div>{{ cat.count }} <span>/</span> {{ attr.total }}</div>
        </div>
        <div class="d-flex flex-nowrap imgRow">
<!--          <img
            v-for="(img, i_idx) in cat.images"
            :key="'img' + i_idx"
            :src="img"
            class="img-attrb"
          />-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "attribute",
  data() {
    return {
      attributes: [
        {
          category: "Super rarity",
          total: 100,
          items: [
           {
              name: "Air Pods",
              count: 1,
              images: [
              ],
            },
            {
              name: "Covid Mask",
              count: 4,
              images: [
              ],
            },
            {
              name: "Crown",
              count: 5,
              images: [
              ],
            },
            {
              name: "Horns",
              count: 9,
              images: [
              ],
            },
            {
              name: "Rainbow",
              count: 3,
              images: [
              ],
            },
          ],
        },
        {
          category: "Uniqueness",
          total: 100,
          items: [
            {
              name: "Bracelet",
              count: 6,
              images: [
              ],
            },
            {
              name: "Glasses",
              count: 7,
              images: [
              ],
            },
           {
              name: "Hair",
              count: 1,
              images: [
              ],
            },
           {
              name: "Hairclip",
              count: 1,
              images: [
              ],
            },
            {
              name: "Hat",
              count: 3,
              images: [
              ],
            },
            {
              name: "Headband",
              count: 3,
              images: [
              ],
            },
            {
              name: "Headphones",
              count: 4,
              images: [
              ],
            },
            {
              name: "Necklace",
              count: 29,
              images: [
              ],
            },
            {
              name: "Necklace & bracelet",
              count: 5,
              images: [
              ],
            },
           {
              name: "Scarf",
              count: 9,
              images: [
              ],
            },
           {
              name: "Singing",
              count: 2,
              images: [
              ],
           },
          {
              name: "Tie",
              count: 2,
              images: [
              ],
           },
           {
              name: "Watch",
              count: 4,
              images: [
              ],
            },
          ],
        },
       {
          category: "Dress",
          total: 100,
          items: [
            {
              name: "Beach",
              count: 6,
              images: [
              ],
            },
            {
              name: "Casual",
              count: 33,
              images: [
              ],
            },
           {
              name: "Chef",
              count: 1,
              images: [
              ],
            },
            {
              name: "Christmas",
              count: 2,
              images: [
              ],
            },
             {
              name: "Detective",
              count: 1,
              images: [
              ],
            },
            {
              name: "Party",
              count: 44,
              images: [
              ],
            },
            {
              name: "Uniform",
              count: 2,
              images: [
              ],
            },
            {
              name: "Winter",
              count: 11,
              images: [
              ],
            },
          ],
        },
      {
          category: "Standout",
          total: 100,
          items: [
            {
              name: "Barefoot",
              count: 5,
              images: [
              ],
            },
            {
              name: "Glasses & Hair",
              count: 3,
              images: [
              ],
            },
            {
              name: "Hair",
              count: 7,
              images: [
              ],
            },
            {
              name: "Hairband",
              count: 1,
              images: [
              ],
            },
            {
              name: "Hairclip",
              count: 1,
              images: [
              ],
            },
            {
              name: "Hat",
              count: 29,
              images: [
              ],
            },
            {
              name: "Headband",
              count: 4,
              images: [
              ],
            },
            {
              name: "Headphones",
              count: 1,
              images: [
              ],
            },
            {
              name: "Love",
              count: 1,
              images: [
              ],
            },

           {
              name: "Teeth",
              count: 5,
              images: [
              ],
            },
           {
              name: "Waist chain",
              count: 1,
              images: [
              ],
            },
          ],
        },
        {
          category: "Earrings",
          total: 100,
          items: [
            {
              name: "Diamond",
              count: 5,
              images: [
              ],
            },
            {
              name: "Gold",
              count: 16,
              images: [
              ],
            },
            {
              name: "Pearl",
              count: 12,
              images: [
              ],
            },
          ],
        },
        {
          category: "Color",
          total: 100,
          items: [
            {
              name: "Black",
              count: 9,
              images: [
              ],
            },
            {
              name: "Blue",
              count: 6,
              images: [
              ],
            },
            {
              name: "Brown",
              count: 1,
              images: [
              ],
            },
              {
              name: "Cyan",
              count: 4,
              images: [
              ],
            },
            {
              name: "Green",
              count: 18,
              images: [
              ],
            },
            {
              name: "Orange",
              count: 5,
              images: [
              ],
            },
            {
              name: "Pink",
              count: 21,
              images: [
              ],
            },
            {
              name: "Purple",
              count: 5,
              images: [
              ],
            },
            {
              name: "Rainbow",
              count: 5,
              images: [
              ],
            },
            {
              name: "Red",
              count: 7,
              images: [
              ],
            },
            {
              name: "Skyblue",
              count: 4,
              images: [
              ],
            },
            {
              name: "Violet",
              count: 4,
              images: [
              ],
            },
            {
              name: "White",
              count: 4,
              images: [
              ],
            },
            {
              name: "Yellow",
              count: 7,
              images: [
              ],
            },
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
.img-attrb {
  object-fit: contain !important;
  width: 50px;
  height: 50px;
  margin: 2px;
}

.imgRow {
  padding: 16px;
  padding-top: 4px;
}

.category {
  padding-bottom: 10px;
}

.img-attrb {
  width:70px;
  height:70px;
  opacity: .8;
}
</style>