<template>
  <div class="attr_tab">
    <div v-for="(attr, idx) in attributes" :key="'attr' + idx">
      <div class="category">
        <div class="text-h6">{{ attr.category }}</div>
      </div>
      <div v-for="(cat, c_idx) in attr.items" :key="'cat' + c_idx">
        <div class="d-flex align-items-center justify-space-between">
          <div>
            {{ c_idx + 1 }}. <u>{{ cat.name }}</u>
          </div>
          <div>{{ cat.count }} <span>/</span> {{ attr.total }}</div>
        </div>
        <div class="d-flex flex-nowrap imgRow">
<!--          <img
            v-for="(img, i_idx) in cat.images"
            :key="'img' + i_idx"
            :src="img"
            class="img-attrb"
          />-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bk-attribute",
  data() {
    return {
      attributes: [
        {
          category: "Super rarity",
          total: 300,
          items: [
           {
              name: "Air Pods",
              count: 2,
              images: [
              ],
            },
            {
              name: "Covid Mask",
              count: 8,
              images: [
              ],
            },
            {
              name: "Crown",
              count: 13,
              images: [
              ],
            },
            {
              name: "Glitch",
              count: 3,
              images: [
              ],
            },
            {
              name: "Horns",
              count: 21,
              images: [
              ],
            },
            {
              name: "Rainbow",
              count: 9,
              images: [
              ],
            },
          ],
        },
        {
          category: "Uniqueness",
          total: 300,
          items: [
            {
              name: "Brarefoot",
              count: 2,
              images: [
              ],
            },
            {
              name: "Bracelet",
              count: 26,
              images: [
              ],
            },
            {
              name: "Crying",
              count: 12,
              images: [
              ],
            },
            {
              name: "Food",
              count: 2,
              images: [
              ],
            },
            {
              name: "Glasses",
              count: 23,
              images: [
              ],
            },
           {
              name: "Hair",
              count: 7,
              images: [
              ],
            },
            {
              name: "Hat",
              count: 3,
              images: [
              ],
            },
            {
              name: "Headband",
              count: 7,
              images: [
              ],
            },
            {
              name: "Headphones",
              count: 3,
              images: [
              ],
            },
            {
              name: "Love",
              count: 7,
              images: [
              ],
            },
            {
              name: "Moustache",
              count: 1,
              images: [
              ],
            },
            {
              name: "Necklace",
              count: 76,
              images: [
              ],
            },
            {
              name: "Scarf",
              count: 18,
              images: [
              ],
            },
            {
                name: "Singing",
                count: 6,
                images: [
                ],
            },
            {
              name: "Teeth",
              count: 8,
              images: [
              ],
           },
            {
              name: "Tie",
              count: 7,
              images: [
              ],
           },
           {
              name: "Watch",
              count: 9,
              images: [
              ],
            },
          ],
        },
        {
          category: "Dress",
          total: 300,
          items: [
            {
              name: "Beach",
              count: 19,
              images: [
              ],
            },
            {
              name: "Casual",
              count: 118,
              images: [
              ],
            },
           {
              name: "Chef",
              count: 3,
              images: [
              ],
            },
            {
              name: "Christmas",
              count: 3,
              images: [
              ],
            },
             {
              name: "Detective",
              count: 1,
              images: [
              ],
            },
            {
              name: "Party",
              count: 118,
              images: [
              ],
            },
            {
              name: "Uniform",
              count: 15,
              images: [
              ],
            },
            {
              name: "Winter",
              count: 24,
              images: [
              ],
            },
          ],
        },
      {
          category: "Standout",
          total: 300,
          items: [
            {
              name: "Barefoot",
              count: 8,
              images: [
              ],
            },
            {
              name: "Crying",
              count: 6,
              images: [
              ],
            },
            {
              name: "Food",
              count: 3,
              images: [
              ],
            },
            {
              name: "Ghost eyes",
              count: 3,
              images: [
              ],
            },
            {
              name: "Glasses",
              count: 16,
              images: [
              ],
            },
            {
              name: "Glasses & Hair",
              count: 2,
              images: [
              ],
            },
            {
              name: "Glasses & Hat",
              count: 4,
              images: [
              ],
            },
            {
              name: "Glasses & Tie",
              count: 1,
              images: [
              ],
            },
            {
              name: "Hair",
              count: 15,
              images: [
              ],
            },
            {
              name: "Hairclip",
              count: 1,
              images: [
              ],
            },
            {
              name: "Hat",
              count: 63,
              images: [
              ],
            },
            {
              name: "Hat & Tie",
              count: 1,
              images: [
              ],
            },
            {
              name: "Headband",
              count: 6,
              images: [
              ],
            },
            {
              name: "Headphones",
              count: 3,
              images: [
              ],
            },
            {
              name: "Love",
              count: 5,
              images: [
              ],
            },
            {
              name: "Necklace",
              count: 28,
              images: [
              ],
            },
           {
              name: "Scarf",
              count: 1,
              images: [
              ],
            },
           {
              name: "Singing",
              count: 2,
              images: [
              ],
            },
           {
              name: "Teeth",
              count: 13,
              images: [
              ],
            },
           {
              name: "Tet",
              count: 1,
              images: [
              ],
            },
           {
              name: "Tie",
              count: 10,
              images: [
              ],
            },
          ],
        },
        {
          category: "Earrings",
          total: 300,
          items: [
            {
              name: "Diamond",
              count: 22,
              images: [
              ],
            },
            {
              name: "Gold",
              count: 44,
              images: [
              ],
            },
            {
              name: "Pearl",
              count: 7,
              images: [
              ],
            },
            {
              name: "Stone",
              count: 57,
              images: [
              ],
            },
          ],
        }
      ],
    };
  },
};
</script>

<style scoped>
.img-attrb {
  object-fit: contain !important;
  width: 50px;
  height: 50px;
  margin: 2px;
}

.imgRow {
  padding: 16px;
  padding-top: 4px;
}

.category {
  padding-bottom: 10px;
}

.img-attrb {
  width:70px;
  height:70px;
  opacity: .8;
}
</style>