<template>
    <div>
        <bk-view></bk-view>
    </div>
</template>

<script>
    import bkView from "../components/bitkittens/view";
    export default {
        components: {bkView}
    }
</script>

<style scoped>

</style>